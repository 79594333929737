import React from 'react'

function MenuEarthIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3318_344151)">
        <path
          d="M16.4599 -0.577855C14.7164 -2.2839 12.4562 -3.46429 9.93469 -3.85938C11.4057 -2.232 12.5835 -0.335481 13.3737 1.75236C14.5295 1.15138 15.572 0.360641 16.4598 -0.57776L16.4599 -0.577855Z"
          fill="white"
        />
        <path
          d="M2.75131 1.75238C3.54166 -0.335561 4.71944 -2.2317 6.1902 -3.85938C3.6689 -3.46467 1.40892 -2.28427 -0.334839 -0.577841C0.552801 0.360563 1.59515 1.15092 2.75121 1.75229L2.75131 1.75238Z"
          fill="white"
        />
        <path
          d="M7.56081 20.0012C7.57792 20.0018 7.59504 20.0018 7.61178 20.0023V12.9688C6.18174 13.024 4.8168 13.3372 3.56458 13.8683C4.43642 16.2019 5.81417 18.2885 7.56082 20.0014L7.56081 20.0012Z"
          fill="white"
        />
        <path
          d="M8.56513 -3.99888C8.54765 -3.99944 8.53091 -3.99944 8.51379 -4V3.03362C9.9438 2.97838 11.3087 2.66514 12.5611 2.13408C11.6895 -0.199577 10.3119 -2.28619 8.56512 -3.9991L8.56513 -3.99888Z"
          fill="white"
        />
        <path
          d="M13.3737 14.2578C12.5833 16.3458 11.4055 18.2417 9.93469 19.8695C12.4561 19.4747 14.7161 18.2941 16.4599 16.5878C15.572 15.6496 14.5297 14.8593 13.3736 14.2579L13.3737 14.2578Z"
          fill="white"
        />
        <path
          d="M2.5197 7.55491H7.6128V3.94007C6.07978 3.88538 4.61646 3.55447 3.27186 2.99219C2.81875 4.43896 2.56151 5.97034 2.51965 7.55491H2.5197Z"
          fill="white"
        />
        <path
          d="M13.6075 8.45312H8.51379V12.0681C10.0465 12.1228 11.51 12.4537 12.8544 13.016C13.3081 11.5694 13.5654 10.0378 13.6074 8.45312H13.6075Z"
          fill="white"
        />
        <path
          d="M12.8547 2.99219C11.5103 3.55451 10.0466 3.88559 8.51379 3.94007V7.55492L13.6068 7.5551C13.5648 5.97086 13.3075 4.4391 12.8544 2.99219L12.8547 2.99219Z"
          fill="white"
        />
        <path
          d="M14.5091 8.45312V8.45368C14.4665 10.1728 14.1799 11.8329 13.6779 13.3978C14.9544 14.0449 16.1047 14.9056 17.0835 15.9316C18.8527 13.9212 19.959 11.3156 20.0649 8.45325L14.5091 8.45312Z"
          fill="white"
        />
        <path
          d="M8.51379 12.9688V20.0024C8.53053 20.0018 8.54765 20.0018 8.56439 20.0012C10.3114 18.2885 11.6895 16.2019 12.5606 13.8681C11.3088 13.3373 9.94414 13.0237 8.51382 12.9688L8.51379 12.9688Z"
          fill="white"
        />
        <path
          d="M14.5097 7.54871H20.0649C19.9593 4.68606 18.8527 2.08074 17.083 0.0703125C16.1049 1.09672 14.9547 1.95755 13.6779 2.60449C14.1801 4.16974 14.4667 5.82932 14.5095 7.54866L14.5097 7.54871Z"
          fill="white"
        />
        <path
          d="M1.61867 8.45317H-3.9364C-3.83056 11.3157 -2.7242 13.921 -0.95492 15.9314C0.0234792 14.905 1.17354 14.0445 2.45012 13.3976C1.94828 11.8324 1.66162 10.1729 1.61886 8.45312L1.61867 8.45317Z"
          fill="white"
        />
        <path
          d="M-0.334839 16.5878C1.40858 18.2939 3.66856 19.4745 6.1902 19.8695C4.71925 18.2418 3.54147 16.3457 2.75131 14.2578C1.59548 14.8586 0.553087 15.6493 -0.334744 16.5877L-0.334839 16.5878Z"
          fill="white"
        />
        <path
          d="M3.27211 13.016C4.61654 12.4539 6.08026 12.1226 7.61331 12.0682V8.45312H2.51965C2.56169 10.0374 2.81894 11.5691 3.27206 13.016H3.27211Z"
          fill="white"
        />
        <path
          d="M3.56459 2.1341C4.81679 2.66495 6.18151 2.97856 7.6119 3.03365L7.61208 -4C7.59497 -3.99944 7.57823 -3.99944 7.56093 -3.99888C5.81394 -2.28611 4.43603 -0.199492 3.56458 2.13431L3.56459 2.1341Z"
          fill="white"
        />
        <path
          d="M2.45008 2.60411C1.17351 1.95717 0.0232625 1.09634 -0.95513 0.0703125C-2.72421 2.08031 -3.8306 4.68592 -3.9364 7.54871H1.61863C1.66141 5.82927 1.94805 4.1695 2.44989 2.60406L2.45008 2.60411Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3318_344151">
          <rect x="0.0637207" width="16" height="16" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MenuEarthIcon
