import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      height: 50,
      width: '100%',
      position: 'relative',
    },

    link: {},

    url: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px 20px 0px 32px',
      transition: 'none',
      height: '100%',
      width: '100%',
      color: theme.palette.common.white,

      '&:hover': {
        backgroundColor: '#303037',
      },

      '& div, & a': {
        transition: 'none',
      },
    },

    active: {
      '.menu-sub-item>&': {
        fontWeight: 'bold',
        '&::before': {
          top: 0,
          bottom: 0,
          margin: 'auto',
          left: 22,
          width: 2,
          height: 'calc(100% - 32px)',
          content: "''",
          display: 'block',
          position: 'absolute',
          backgroundColor: '#9B1469',
        },
      },
    },

    linkContent: {
      display: 'flex',
      height: '100%',
      width: '100%',
      fontSize: 14,
      alignItems: 'center',
      position: 'relative',
    },

    icon: {
      position: 'absolute',
      margin: 'auto',
      top: 0,
      bottom: 0,
      left: -22,
      width: 14,
      height: 14,
      '&>svg': {
        height: '100%',
        width: '100%',
      },
    },
    arrow: {
      position: 'absolute',
      margin: 'auto',
      top: 0,
      bottom: 0,
      right: 0,
      width: 14,
      height: 14,
      '& svg': {
        height: '100%',
        width: '100%',
      },
    }
  });
});
