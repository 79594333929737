import * as React from "react";

const AgglomerationIcon = () => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx={7.5} cy={7.5} r={2.5} fill="white" />
        <circle cx={3} cy={2} r={2} fill="white" />
        <circle cx={3} cy={11} r={2} fill="white" />
        <circle cx={13.5} cy={11.5} r={1.5} fill="white" />
        <circle cx={9} cy={15} r={1} fill="white" />
        <circle cx={12.5} cy={2.5} r={1.5} fill="white" />
    </svg>
);

export default AgglomerationIcon;