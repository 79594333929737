import { initRestify } from 'redux-restify';
import { getToken } from 'src/storage';
import {
  DEFAULT_API_NAME,
  DEFAULT_ALLOWED_NO_TOKEN_ENDPOINTS,
  DEFAULT_API_PREFIX,
  DEFAULT_API_HOST,
} from 'src/api-url-schema';

import { logout } from 'src/auth';
import modals from 'src/modals';
import app from 'src/app';
import {
  STATIC_API_NAME,
  STATIC_ALLOWED_NO_TOKEN_ENDPOINTS,
  STATIC_API_PREFIX,
  STATIC_API_HOST,
} from './static-api-url-schema';

const configRestify = () => {
  const defaultHttpCodesCallbacks = (code: number) => {
    if (code >= 500) {
      return () => modals.actions.showMessageBoxModal({
        text: 'A server error has occurred! Try reloading the page!',
      });
    }
    if (code === 423) {
      return () => modals.actions.showMessageBoxModal({
        text: 'The result is not ready yet. Try it in a minute.',
      });
    }
    if (code === 0) {
      return () => app.actions.toast.error('An error occurred while sending the request! Check your internet connection.');
    }
    return {
      401: logout,
    };
  };

  const defaultGetToken = () => {
    const token = getToken();
    return token && token.replace('null', '');
  };
  const apiDefinitions = {
    [DEFAULT_API_NAME]: {
      getToken: defaultGetToken,
      authMethod: '',
      apiHost: DEFAULT_API_HOST,
      apiPrefix: DEFAULT_API_PREFIX,
      allowedNoTokenEndpoints: DEFAULT_ALLOWED_NO_TOKEN_ENDPOINTS,
      httpCodesCallbacks: defaultHttpCodesCallbacks,
    },
    [STATIC_API_NAME]: {
      apiHost: STATIC_API_HOST,
      apiPrefix: STATIC_API_PREFIX,
      allowedNoTokenEndpoints: STATIC_ALLOWED_NO_TOKEN_ENDPOINTS,
      httpCodesCallbacks: defaultHttpCodesCallbacks,
    },
  };
  const modelsContext = require.context('./', true, /.*\/models\/[A-z0-9-_]*\.model\.([jt])sx?$/);
  const modelsDefinitions = modelsContext.keys().reduce((memo: object, key: string) => ({
    ...memo,
    [key
      .replace('.model', '')
      .replace(/\.\/|\.([jt])sx?/g, '')
      .split('/')
      .reverse()[0]
    ]: modelsContext(key).default,
  }), {});

  const formsContext = require.context('./', true, /.*\/forms\/[A-z0-9-_]*\.form\.([jt])sx?$/);
  const formsDefinitions = formsContext.keys().reduce((memo: object, key: string) => ({
    ...memo,
    [key
      .replace('.form', 'Form')
      .replace(/\.\/|\.([jt])sx?/g, '')
      .split('/')
      .reverse()[0]
    ]: formsContext(key).default,
  }), {});

  initRestify({
    apiDefinitions,
    modelsDefinitions,
    formsDefinitions,
  });
};

export default configRestify;
