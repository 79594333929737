import React, { FC, useMemo } from 'react';
import clsx from 'classnames';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { MenuRenderers, SideMenu } from 'src/pages';
import { LogoIcon, MenuSymmetricEarthIcon, AgglomerationIcon } from 'src/components';
import { ffOxygen } from 'src/theme/typography';
import { AcProjectListForm } from 'src/cluster/common';
import { AcProjectsModel } from 'src/cluster/projects';
import { Maybe } from 'monet';
import { RouteComponentProps } from 'react-router';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      backgroundColor: 'rgb(51, 59, 70)',
      overflowY: 'auto',
      zIndex: theme.zIndex.snackbar,
      '& *': {
        fontFamily: ffOxygen,
      },
    },
    logo: {
      padding: '23px 0 23px 10px',
    },
  });
});

const menuRenderers: MenuRenderers = {
  freight: {
    label: 'systems.freight.captions.title',
    icon: <MenuSymmetricEarthIcon />,
    pages: {},
  },
  agglomeration: {
    label: 'systems.agglomeration.captions.title',
    icon: <AgglomerationIcon />,
    pages: {
      editor: {
        label: 'systems.editor.captions.title'
      },
      balance: {
        label: 'systems.balance.captions.title'
      },
      distribution: {
        label: 'systems.distribution.captions.title'
      },
      dashboard: {
        label: 'systems.dashboard.captions.title'
      },
    },
  },
};

interface InjectedProps {
  selectedProjectForm: AcProjectListForm;
  projects: Maybe<AcProjectsModel[]>;
}

interface BaseProps {
  className?: string;
}

type Props = RouteComponentProps & InjectedProps & BaseProps;
type Clusters = keyof typeof menuRenderers;
const MainMenu: FC<Props> = (props) => {
  const {
    selectedProjectForm, className, projects, location,
  } = props;

  const classes = useStyles();
  const [selectedCluster] = location.pathname.slice(1).split('/');

  const selectedProject = projects
    .orJust([])
    .find((project) => project.id === selectedProjectForm.selectedProject);

  const menuRendersWidthExtraLinks = useMemo(() => {
    if (!selectedProject?.externalLinks || selectedCluster !== 'agglomeration') return menuRenderers;
    const newMenuRenderers = { ...menuRenderers };
    const selectedClusterMenu = newMenuRenderers[selectedCluster as Clusters];
    if (!newMenuRenderers) return undefined;

    const extraPages = Object.fromEntries(
      selectedProject.externalLinks.map((extraLink) => {
        return [extraLink.name, extraLink];
      }),
    );
    return {
      ...newMenuRenderers,
      [selectedCluster]: {
        ...selectedClusterMenu,
        pages: {
          ...selectedClusterMenu.pages,
          ...extraPages,
        },
      },
    };
  }, [selectedProject, selectedCluster]);

  return (
    <aside className={clsx(classes.root, className)}>
      <div className={classes.logo}>
        <LogoIcon />
      </div>
      <SideMenu menuRenderers={menuRendersWidthExtraLinks} />
    </aside>
  );
};

export default MainMenu;
