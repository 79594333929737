import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() => {
  return createStyles({
    child: {
      backgroundColor: '#31353E',
      boxSizing: 'border-box',
    },
  });
});
